<template>
  <div class="food">
    <h3 class="sub-title">Виды лечебного питания, включая специализированные продукты лечебного питания</h3>
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header>
          {{getDiet1.title}}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-skeleton-loader
           v-if="!load"
            class="mx-auto"
            type="table-tbody"
          ></v-skeleton-loader>
          <table class="table" v-else>
            <thead>
              <tr>
                <th
                  v-for="th in getDiet1.header"
                  :key="th"
                  class="text-center">
                  {{th}}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(tr, index) in getDiet1.food"
                :key="index"
              >
              <td v-for="(td, i) in tr" :key="i"  class="text-center" @click="openNote({
                  section: 'diet1',
                  columnName: i,
                  filterValue: td.value,
                })">
                <div class="nothint">
                  {{ td.value }}
                </div>
                <div class="hint" v-if="td.note">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        v-bind="attrs"
                        v-on="on"
                      >
                        ({{td.note.num}})
                      </span>
                    </template>
                    <span>{{td.note.name}}</span>
                  </v-tooltip>
                </div>
              </td>
              </tr>
            </tbody>
          </table>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          {{getDiet2.title}}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-skeleton-loader
           v-if="!getDiet2.food.length"
            class="mx-auto"
            type="table-tbody"
          ></v-skeleton-loader>
          <table class="table" v-else>
            <thead>
              <tr>
                <th
                  v-for="th in getDiet2.header"
                  :key="th"
                  class="text-center">
                  {{th}}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(tr, index) in getDiet2.food"
                :key="index"
              >
              <td v-for="(td, i) in tr" :key="i"  class="text-center" @click="openNote({
                  section: 'diet2',
                  columnName: i,
                  filterValue: td.value,
                })">
                <div class="nothint">
                  {{ td.value }}
                </div>
                <div class="hint" v-if="td.note">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        v-bind="attrs"
                        v-on="on"
                      >
                        ({{td.note.num}})
                      </span>
                    </template>
                    <span>{{td.note.name}}</span>
                  </v-tooltip>
                </div>
              </td>
              </tr>
            </tbody>
          </table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-dialog
      max-width="558"
     >
      <v-card>
        <v-textarea
          name="textarea"
          class="textarea"
          outlined
          v-model="query.name"
          label="Введите заметку"
        ></v-textarea>
        <v-card-actions>
          <v-btn
            color="primary darken-1"
            text
            @click="search = true"
          >

          </v-btn>

          <v-btn
            color="primary darken-1"
            text
            @click="addNewNote()"
          >
            Добавить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialog"
      max-width="558"
     >
      <v-card>

        <v-card-title class="text-h6">
          <v-row>
            <div class="col-12 d-flex justify-space-between">
              <span>Добавить заметку</span>
              <v-icon
                color="darken-2"
                @click="close()"
              >
                mdi-close
              </v-icon>
            </div>
            <div class="col-12">
              <v-autocomplete
                v-if="searchNote"
                :items="getNotes2"
                label="Поиск по заметкам"
                v-model="query.name"
              ></v-autocomplete>
            </div>
          </v-row>

        </v-card-title>

        <v-textarea
          name="textarea"
          class="textarea"
          outlined
          v-model="query.name"
          label="Введите заметку"
        ></v-textarea>
        <v-card-actions>
          <v-btn
            color="primary darken-1"
            text
            @click="searchNote = !searchNote"
          >
            Выбрать заметку из справочника
          </v-btn>

          <v-btn
            color="primary darken-1"
            text
            @click="addNewNote()"
          >
            Добавить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex'

  export default {
    name: 'Food',
    data () {
      return {
        dialog: false,
        key: 0,
        query: {
          standartId: '',
          name: '',
          typeCode: 1,
          section: 'diet1',
          linkName: "",
          columnName: '',
          filterName: "",
          filterPage: '',
          filterFreq: "",
          filterCountSKD: "",
          filterUnit: "",
          filterSDD: "",
          filterATCCode: ""
        },
        load: false,
        searchNote: ''
      }
    },
    computed : {
      ...mapGetters({
          getDiet1: 'smp/getDiet1',
          getDiet2: 'smp/getDiet2',
          getId: 'smp/getId',
          getNotes2: 'lookups/getNotes2'
      }),
    },
    methods: {
      ...mapActions({
          addNote: 'smp/addNote',
          standart: 'smp/standart',
          notes: 'lookups/notes'
      }),
      openNote(data) {
        this.query.filterName = ""
        this.query.filterATCCode = ""
        this.query.filterFreq = ""
        this.query.filterUnit = ""
        this.query.filterSDD = ""
        this.query.filterCountSKD = ""
        if(data.section == 'diet1'){
          switch (data.columnName) {
            case 0:
            this.query.columnName = 'name'
            this.query.filterName = data.filterValue
            break;
            case 1:
            this.query.columnName = 'midRate'
            this.query.filterFreq = String(data.filterValue)
            break;
            case 2:
            this.query.columnName = 'midCount'
            this.query.filterCountSKD = String(data.filterValue)
            break;
            default:
          }
        }
        else{
          switch (data.columnName) {
            case 0:
            this.query.columnName = 'name'
            this.query.filterName = data.filterValue
            break;
            case 1:
            this.query.columnName = 'midRate'
            this.query.filterFreq = String(data.filterValue)
            break;
            case 2:
            this.query.columnName = 'sdd'
            this.query.filterSDD = data.filterValue
            break;
            case 3:
            this.query.columnName = 'skd'
            this.query.filterCountSKD = data.filterValue
            break;
            case 4:
            this.query.columnName = 'unit'
            this.query.filterUnit = data.filterValue
            break;
            default:
          }
        }

        this.query.section = data.section
        this.dialog = true
        this.query.standartId = this.getId
        console.log(data,this.query)
      },
      close() {
        this.dialog = false
        this.query.name = ""
        this.searchNote = false
      },
      addNewNote() {
        this.dialog = false
        this.addNote(this.query).then(() => {
          this.standart(this.$route.params.id)
          this.key = this.key++
        })
      }
    },
    mounted() {
      this.notes().then(() => this.load = true)
    }
  }
</script>

<style lang="scss" scoped>
  @import "~@/scss/vars.scss";
  @import "~@/scss/mixins.scss";
  @import "./style.scss";
</style>
